import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Login from "./login";
import React from "react";
import Contact from "./contact";
import "../styles/home.scss";
import example from "../static/example.cha";
import explanation from "../static/file_changes.txt";

function Home(props) {
    const { t } = props;
    const showVideo = process.env.REACT_APP_ENVIRONMENT === "production";

    return (
        <Container className="home">
            <Row className="info-block">
                <Col className="text">
                    <Container id="description">
                        <h3>
                            <b>{t("home.info.title")}</b>
                        </h3>
                        <p>{t("home.info.presentation")}</p>
                    </Container>
                    <Contact />
                </Col>
                <Col className="video">
                    {showVideo && <ReactPlayer url="https://vimeo.com/454285098" controls={true} />}
                </Col>
            </Row>

            <Row className="login-block">
                <Container>
                    <Row>
                        <h2>
                            <b>{t("home.login.title")}</b>
                        </h2>
                    </Row>
                    <Row>
                        <Col className="login" xs={10}>
                            <Container className="login-container" fluid>
                                <Login />
                            </Container>
                        </Col>
                        <Col className="docs" sm={2}>
                            <h5>{t("home.docs.title")}</h5>
                            <div>
                                <a href={example} download={t("home.docs.inputFile")}>
                                    {t("home.docs.input")}
                                </a>
                            </div>
                            <div>
                                <a href={explanation} download={t("home.docs.tutoFile")}>
                                    {t("home.docs.tutorial")}
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Row>

            <Row>
                <Container>
                    <h4>
                        <b>{t("home.privacy.title")}</b>
                    </h4>
                    <p>{t("home.privacy.body")}</p>
                </Container>
            </Row>
        </Container>
    );
}

export default withTranslation()(Home);
